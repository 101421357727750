<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useTaoModals();

const handleOpenSeason = () =>
	loginGuard({
		success: () => {
			open("LazyOModalSeasonTower");

			dispatchGAEvent({
				event: "click_button",
				button_name: "season_popup",
				location: "sidebar_menu"
			});
		},
		fail: () => {
			open("LazyOModalSignup", { location: "sidebar_season" });
		}
	});
</script>
<template>
	<div class="event-item season" @click="handleOpenSeason">
		<div class="event-wrap">
			<NuxtImg
				src="/nuxt-img/seasons/hunt/sidebar-hunt.png"
				class="logo"
				alt="hunt season"
				format="webp"
				width="96"
				height="96"
				loading="lazy"
			/>
		</div>
		<AText class="color-primary-400" :modifiers="['uppercase', 'bold']">
			<span>{{ t("Treasure Hunt") }}</span>
		</AText>
	</div>
</template>
<style lang="scss" scoped>
.event-wrap {
	min-width: 48px;
	height: 48px;
	position: relative;

	.logo {
		width: 48px;
		height: 48px;
		transform: translateX(2px);
	}
}
</style>
